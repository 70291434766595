body {
    background-color: #F6F8FA;
    scrollbar-width: thin; /* スクロールバーの幅を調整 */
    scrollbar-color: #F6F8FA #F6F8FA; /* スクロールバーの色を設定 */
}
html {
    background-color: #F6F8FA;
}

/* スクロールバーのトラック（背景） */
::-webkit-scrollbar-track {
    background-color: #F6F8FA;
}
  
/* スクロールバーのハンドル（スクロールバーの移動部分） */
::-webkit-scrollbar-thumb {
    background-color: #F6F8FA;
}

/* スクロールバーのコーナー（ハンドルとトラックの接合部分） */
::-webkit-scrollbar-corner {
    background-color: #F6F8FA;
}